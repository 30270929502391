import { createRouter, createWebHistory } from 'vue-router';


const routes = [
    {
        path: '/',
        name: 'gameStart',
        component: () => import('@/Views/FlipaCoin.vue')
    },{
        path: '/game',
        name: 'game',
        component: () => import('@/Views/comview.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
