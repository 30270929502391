import { createApp } from 'vue';
import App from './App.vue';
import 'solana-wallets-vue/styles.css';
import './index.css';
import SolanaWallets from 'solana-wallets-vue';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import router from '@/router/index';
const walletOptions = {
  wallets: [new PhantomWalletAdapter()],
  autoConnect: true,
};

const app = createApp(App);
app.use(SolanaWallets, walletOptions).use(router);
app.mount('#app');
