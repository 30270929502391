<template>

  <router-view></router-view>
</template>

<script lang="ts" setup>

</script>

<style>
/*#app input,*/
/*#app button {*/
/*  @apply border border-gray-400 rounded px-4 py-1 max-w-60 mx-2;*/
/*}*/
/*#app button {*/
/*  @apply !bg-blue-200 font-semibold  min-w-32;*/
/*}*/
</style>
